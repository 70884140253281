<template>
  <section class="hero is-danger is-fullheight">
    <div class="hero-body lfondo">
      <div class="modulo box mod-login">
        <div class="columns m-0 is-vcentered">
          <div class="column p-0 is-half">
            <div
              class="box mod has-text-centered mlogin-logo"
              onClick="location.href='http://sharagamo.com/prueba-sanofi/'"
            >
              <figure class="image is-128x128 is-inline-block">
                <img class="" src="assets/logo/sanofi-login.png" />
              </figure>
            </div>
          </div>
          <div class="column gris-login mlogin-derecha is-half">
            <div class="box mod gris-login py-5 has-text-centered">
              <div class="content">
                <h3 class="is-size-7 has-text-weight-bold tazul">Login</h3>
              </div>
              <div class="field">
                <p class="control has-icons-left has-icons-right input-login">
                  <input
                    class="input input-login"
                    type="text"
                    placeholder="Usuario"
                    v-model="email"
                    v-bind:class="{ 'is-danger': $v.email.$invalid }"
                  />
                  <span class="icon is-small is-left">
                    <i class="fas fa-envelope"></i>
                  </span>
                </p>
                <span class="error" v-if="!$v.email.required"
                  >Campo requerido</span
                >
              </div>
              <div class="field">
                <p class="control has-icons-left input-login">
                  <input
                    class="input input-login"
                    type="password"
                    placeholder="Contraseña"
                    v-model="password"
                    v-bind:class="{ 'is-danger': $v.password.$invalid }"
                  />
                  <span class="icon is-small is-left">
                    <i class="fas fa-lock"></i>
                  </span>
                </p>
                <span class="error" v-if="!$v.password.required"
                  >Campo requerido</span
                >
              </div>
              <br />
              <div class="field">
                <p class="control">
                  <button
                    v-bind="{ disabled: $v.$invalid }"
                    class="button is-success is-fullwidth blogin verde-plaza"
                    @click="check_login"
                  >
                    ENTRAR
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import { required } from "vuelidate/lib/validators";
import { post_request, error_toast } from "@/utils";
// import sha1 from "sha1";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      email: "",
      password: "",
    };
  },
  validations: {
    email: {
      required,
    },
    password: {
      required,
    },
  },
  methods: {
    check_login() {
      let vm = this;
      if (!vm.$v.$invalid) {
        post_request(vm, "/auth/do-auth", {
          email: vm.email,
          password: vm.password,
        })
          .then((response) => {
            vm.$session.set("token", response.token);
            vm.$session.set("user", response.user);
            this.$router.replace({ name: "Home" });
          })
          .catch((err) => {
            console.log(err);
            error_toast(vm, err);
          });
      }
    },
  },
  mounted() {
    let sessionExists = this.$session.exists();
    if (sessionExists) this.$router.replace({ name: "Home" });
  },
};
</script>

<style>
@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");
</style>
